import { FunctionalComponent } from 'preact'
import Deck from './deck'

const Center: FunctionalComponent = () => (
	<div className="game-center">
		<Deck />
	</div>
)

export default Center
