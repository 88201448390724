import { FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'
import { LogContext } from '../state/log'
import Card from './card'

const Deck: FunctionalComponent = () => {
	const log = useContext(LogContext)

	return (
		<>
			<div className="deck">
				<div className="card-outer">
					<div className="card-inner card-hidden"></div>
					<span className="card-count">{log.state.deckSize()}</span>
				</div>
			</div>
			<div className="deck">
				{log.state.sellPile.length > 0 && (
					<Card {...log.state.sellPile[log.state.sellPile.length - 1]}>
						<span className="card-count">{log.state.sellPile.length}</span>
					</Card>
				)}
			</div>
		</>
	)
}
export default Deck
