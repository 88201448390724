export class ResultError extends Error {
	name = 'ResultError'
}

export default class Result<T, E> {
	protected constructor(ok: null, err: E, success: false)
	protected constructor(ok: T, err: null, success: true)
	protected constructor(
		public readonly ok: T | null,
		public readonly err: E | null,
		protected readonly success: boolean,
	) {}

	static ok<T, E>(val: T) {
		return new Result<T, E>(val, null, true)
	}
	static err<T, E>(err: E) {
		return new Result<T, E>(null, err, false)
	}

	match<R>(funcs: { ok(val: T): R; err(err: E): R }): R
	match<R>(funcs: { ok(val: T): R }): R | void
	match<R>(funcs: { err(err: E): R }): R | void
	match<R>(funcs: { ok?(val: T): R; err?(err: E): R }): R | void {
		if (this.isOk()) {
			return funcs.ok?.(this.unwrap())
		} else {
			return funcs.err?.(this.unwrapErr())
		}
	}

	isOk() {
		return this.success
	}
	isErr() {
		return !this.success
	}

	expect(err: string) {
		if (this.isErr()) {
			throw new ResultError(err)
		}

		return this.ok as T
	}
	unwrap() {
		if (!this.success) {
			throw new ResultError("Called 'unwrap' on an 'err' value: " + this.err)
		}

		return this.ok as T
	}
	unwrapErr() {
		if (this.success) {
			throw new ResultError("Called 'unwrapErr' on an 'ok' value.")
		}

		return this.err as E
	}
}
