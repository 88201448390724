import { FunctionComponent } from 'preact'
import { Children } from 'preact/compat'

const Stack: FunctionComponent = ({ children }) => (
	<div
		className={`card-stack ${Children.count(children) === 4 ? 'mafia' : ''}`}
	>
		{children}
	</div>
)

export default Stack
