import { FunctionComponent } from 'preact'
import { useContext } from 'preact/hooks'
import { LogContext } from '../state/log'
import Import from './import'

export const MoveColumn: FunctionComponent<{ idx: number }> = ({ idx }) => {
	const log = useContext(LogContext)

	return (
		<div className="move-column">
			<div className="move-column-header">{log.players[idx].symbol}</div>
			{log.moves
				.filter((_, i) => i % 5 === idx)
				.map((move, i) => {
					const moveStr = move.toString()
					let [semi, full] = moveStr.split(/(?<!.*[=-].*)(?=[=-])/)

					full ??= ''

					return (
						<div
							className={`move-notation ${
								1 + i * 5 + idx === log.turn
									? log.semiturn
										? 'move-semi'
										: 'move-active'
									: 1 + i * 5 + idx < log.turn
									? 'move-played'
									: 'move-future'
							}`}
							key={`${i}${move}`}
							onClick={() => {
								if (log.turn === 1 + i * 5 + idx) {
									if (log.semiturn) {
										log.advanceSemiturn()
									} else if (log.onSemiturn()) {
										log.reverseSemiturn()
									}
								} else {
									log.setTurn(1 + i * 5 + idx)
									if (log.onSemiturn()) {
										log.reverseSemiturn()
									}
								}
							}}
							data-text={semi + full}
						>
							<div className="move-bar"></div>
							<span className="semiturn-notation">{semi}</span>
							<span className="fullturn-notation">{full}</span>
						</div>
					)
				})}
		</div>
	)
}

const Moves: FunctionComponent = () => (
	<div className="moves">
		<div className="move-header">
			<span className="move-title">Move List</span>
			<Import />
		</div>
		<div className="move-list">
			{[0, 1, 2, 3, 4].map(i => (
				<MoveColumn idx={i} key={i} />
			))}
		</div>
	</div>
)

export default Moves
