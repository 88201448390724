import { ComponentChildren, FunctionComponent } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { JSXInternal } from 'preact/src/jsx'
import GameLog, { LogContext, Player } from '../state/log'
import Result from '../util/result'

export const GAMES = [
	{
		players: [
			{ name: 'meth', symbol: 'M' },
			{ name: 'ameya', symbol: 'A' },
			{ name: 'graphs', symbol: 'G' },
			{ name: 'stuff', symbol: 'S' },
			{ name: 'sadn00b', symbol: 'B' },
		],
		moves: [
			'+2',
			'+K',
			'+K',
			'+7',
			'+0',
			'+8',
			'+0',
			'+6',
			'+K',
			'+K',
			'+3',
			'+2',
			'+9',
			'+4',
			'+K=M:28',
			'+3',
			'+K=B:28',
			'+5',
			'+K',
			'+K',
			'+4-334',
			'+8',
			'+5',
			'+7',
			'+9',
			'+5',
			'+0',
			'+6',
			'+7',
			'+3=A:00',
			'+K',
			'+4',
			'+K',
			'+K=B:00',
			'+9=S:00',
			'+K=B:00',
			'+2',
			'+4',
			'+K=A:88',
			'+3',
			'+3',
			'+7',
			'+K=B:99',
			'+8',
			'+3',
			'+K=B:33',
			'+6',
			'+9!+5=M:50!+8',
			'+4=G:89#!+0',
			'+K=M:33',
			'+6=B:33',
			'+K=S:78#',
			'+2K',
		],
	},
]

function validateImport(): Result<ComponentChildren, ComponentChildren> {
	let players: Player[] = []
	for (let i = 1; i <= 5; i++) {
		const { value: name } = document.getElementById(
			`import-p${i}`,
		) as HTMLInputElement
		const { value: symbol } = document.getElementById(
			`import-sym${i}`,
		) as HTMLInputElement

		if (!symbol || players.some(p => p.symbol === symbol)) {
			return Result.err(
				<span className="import-error">
					Error: Each player must have a unique, non-empty symbol
				</span>,
			)
		} else if (/[K0-9]/.test(symbol)) {
			return Result.err(
				<span className="import-error">
					Error: Player symbols cannot contain numbers or 'K'
				</span>,
			)
		}
		players.push({ name, symbol })
	}

	const { value: moves } = document.getElementById(
		`import-moves`,
	) as HTMLInputElement
	const moveArr = moves.split(/\s+/g)
	const log = new GameLog(players)

	return log
		.loadGame(players, moveArr)
		.match<Result<ComponentChildren, ComponentChildren>>({
			ok(log) {
				log.setTurn(moveArr.length)
				if (log.state.deckSize() > 0) {
					return Result.ok(
						<span className="import-warning">
							Warning: This game's deck has not been exhausted (the game can
							still be analyzed)
						</span>,
					)
				} else {
					return Result.ok(<></>)
				}
			},
			err(err) {
				return Result.err(<span className="import-error">Error: {err}</span>)
			},
		})
}

const Import: FunctionComponent = () => {
	const log = useContext(LogContext)
	const [show, setShow] = useState(false)
	const [valid, setValid] = useState<
		Result<ComponentChildren, ComponentChildren>
	>(Result.err(<></>))
	const handleOpen: JSXInternal.MouseEventHandler<HTMLAnchorElement> = e => {
		e.preventDefault()
		setShow(true)
	}
	const handleClose: JSXInternal.MouseEventHandler<HTMLDivElement> = e => {
		e.preventDefault()
		setShow(false)
	}
	const handleSWCImport = () => {
		const { value } = document.getElementById(
			'import-swc-select',
		) as HTMLSelectElement
		const game = GAMES[parseInt(value)]

		log.loadGame(game.players, game.moves).unwrap()
		setShow(false)
		window.history.pushState(
			null,
			'',
			`${window.location.href.split('#')[0]}#swc${parseInt(value) + 1}`,
		)
	}
	const handleCustomImport = () => {
		let players: Player[] = []
		for (let i = 1; i <= 5; i++) {
			const { value: name } = document.getElementById(
				`import-p${i}`,
			) as HTMLInputElement
			const { value: symbol } = document.getElementById(
				`import-sym${i}`,
			) as HTMLInputElement
			players.push({ name, symbol })
		}

		const { value: moves } = document.getElementById(
			`import-moves`,
		) as HTMLInputElement
		const moveArr = moves.split(/\s+/g)
		localStorage.setItem('savedPlayers', JSON.stringify(players))
		localStorage.setItem('savedMoves', JSON.stringify(moveArr))
		log.loadGame(players, moveArr).unwrap()
		setShow(false)
	}
	const revalidate = () => {
		setValid(validateImport())
	}

	return (
		<>
			<a className="import-btn" href="#" onClick={handleOpen}>
				Import Game
			</a>

			<div
				className={`import-wrapper ${show ? 'import-show' : 'import-hide'}`}
				onClick={handleClose}
			>
				<div className="import" onClick={e => e.stopPropagation()}>
					<h1>Import Game</h1>
					<p>
						Use SWC Game:{' '}
						<select name="import-swc-select" id="import-swc-select">
							{GAMES.map((_, i) => (
								<option value={i} key={i}>
									Game #{i + 1}
								</option>
							))}
						</select>
						<button id="import-swc-btn" onClick={handleSWCImport}>
							Import
						</button>
					</p>
					<p>or import your own game:</p>
					<p>
						<strong>Players: </strong>
					</p>
					<ol>
						{[1, 2, 3, 4, 5].map(i => (
							<li>
								<input
									type="text"
									name={`import-p${i}`}
									id={`import-p${i}`}
									className="import-player-input"
									placeholder={`Player ${String.fromCharCode(
										'A'.charCodeAt(0) + i - 1,
									)}`}
								/>{' '}
								(
								<input
									type="text"
									name={`import-sym${i}`}
									id={`import-sym${i}`}
									className="import-symbol-input"
									placeholder={String.fromCharCode('A'.charCodeAt(0) + i - 1)}
									onChange={revalidate}
								/>
								)
							</li>
						))}
					</ol>
					<p>
						<strong>Moves:</strong>
					</p>
					<textarea
						name="import-moves"
						id="import-moves"
						cols={70}
						rows={10}
						onChange={revalidate}
					></textarea>
					<p>
						{valid.ok ?? valid.err}
						<button
							id="import-custom-btn"
							disabled={valid.isErr()}
							onClick={handleCustomImport}
						>
							Import
						</button>
					</p>
				</div>
			</div>
		</>
	)
}

export default Import
