import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'
import { JSXInternal } from 'preact/src/jsx'
import GameLog, { GameMove, LogContext } from '../state/log'
import { Suit } from '../state/state'
import Card from './card'
import Center from './center'
import { GAMES } from './import'
import Moves from './moves'
import Player from './player'
import Row from './row'
import Stack from './stack'

const View: FunctionComponent = () => {
	const [[log], setLog] = useState(() => {
		const log = new GameLog([
			{ name: 'Player A', symbol: 'A' },
			{ name: 'Player B', symbol: 'B' },
			{ name: 'Player C', symbol: 'C' },
			{ name: 'Player D', symbol: 'D' },
			{ name: 'Player E', symbol: 'E' },
		])

		const num = /^#swc(\d+)/.exec(window.location.hash)
		if (num && GAMES[parseInt(num[1]) - 1]) {
			return [
				log
					.loadGame(
						GAMES[parseInt(num[1]) - 1].players,
						GAMES[parseInt(num[1]) - 1].moves,
					)
					.unwrap(),
			]
		}

		try {
			log
				.loadGame(
					JSON.parse(localStorage.getItem('savedPlayers')!),
					JSON.parse(localStorage.getItem('savedMoves')!),
				)
				.unwrap()
		} finally {
			return [log]
		}
	})
	log.once('update', () => setLog([log]))

	const handleKeypress: JSXInternal.KeyboardEventHandler<
		HTMLDivElement
	> = event => {
		if (event.key === 'ArrowLeft') {
			log.reverseSemiturn()
		} else if (event.key === 'ArrowRight') {
			log.advanceSemiturn()
		}
	}

	return (
		<LogContext.Provider value={log}>
			<div className="view" tabIndex={0} onKeyDown={handleKeypress}>
				<div className="game">
					<Center />
					<Player idx={3} />
					<Player idx={4} />
					<Player idx={2} />
					<Player idx={1} />
					<Player idx={0} />
				</div>
				<Moves />
			</div>
		</LogContext.Provider>
	)
}
export default View
