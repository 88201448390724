import { ComponentChildren } from 'preact'
import seedrandom from 'seedrandom'
import Card from '../components/card'
import Stack from '../components/stack'
import { Action, ActionType, GameMove, Player } from './log'

export enum Suit {
	Spades,
	Clubs,
	Hearts,
	Diamonds,
}

export class GameCard {
	constructor(public suit: Suit, public id: number) {}

	equals(other: GameCard | null | undefined) {
		return this.suit === other?.suit && this.id === other?.id
	}

	symbol() {
		if (this.id >= 11 || this.id === 1) {
			return 'K'
		} else {
			return this.id
		}
	}
}

export default class GameState {
	turn = 0
	hands: GameCard[][]
	sellPile: GameCard[] = []
	lastCards: GameCard[] = []
	constructor(public players: Player[]) {
		this.hands = players.map(_ => [])
	}

	score(idx: number) {
		let maxCards = 0
		for (const hand of this.hands) {
			maxCards = Math.max(maxCards, hand.length)
		}
		return Math.round(25 * (this.hands[idx].length / maxCards) ** 1.5) || 0
	}

	stacks(idx: number) {
		const stacks: ComponentChildren[][] = new Array(9).fill(0).map(_ => [])
		// debugger

		let firstCard = null
		for (const card of this.hands[idx]) {
			if (card.id >= 2 && card.id <= 10) {
				stacks[card.id - 2].push(<Card {...card} />)
				if (!firstCard) {
					if (card.id === 6) {
						firstCard = 6
					} else if (card.id === 9) {
						firstCard = 9
					}
				}
			}
		}

		const components: ComponentChildren[] = []
		for (let i = 0; i < 9; i++) {
			if ((i + 2 === 6 && firstCard === 9) || (i + 2 === 9 && firstCard === 6))
				continue
			if (i + 2 === 9 && firstCard === 9 && stacks[6 - 2].length) {
				components.push(<Stack>{stacks[6 - 2]}</Stack>)
			}

			if (stacks[i].length) {
				components.push(<Stack>{stacks[i]}</Stack>)
			}

			if (i + 2 === 6 && firstCard === 6 && stacks[9 - 2].length) {
				components.push(<Stack>{stacks[9 - 2]}</Stack>)
			}
		}

		return components
	}
	knightStacks(idx: number) {
		return this.hands[idx]
			.filter(card => card.symbol() === 'K')
			.map(card => (
				<Stack>
					<Card {...card} />
				</Stack>
			))
	}

	advanceTurn() {
		this.turn = (this.turn + 1) % 5
	}

	count(idx: number, card: number | 'K') {
		return this.hands[idx].filter(hCard => hCard.symbol() === card).length
	}

	move(move: GameMove) {
		this.lastCards = []
		for (const action of move.actions) {
			this.act(action)
		}
		this.advanceTurn()
	}
	act(action: Action) {
		switch (action.type) {
			case ActionType.Draw:
				for (const card of action.cards) {
					const drawnCard = this.pickCard(card)
					this.lastCards.push(drawnCard)
					this.hands[this.turn].push(drawnCard)
				}
				while (this.count(this.turn, 'K') > 4) {
					this.lastCards.push(this.takeCard(this.turn, 'K'))
					this.lastCards.push(this.takeCard(this.turn, 'K'))
				}
				break

			case ActionType.Sell:
				{
					for (const card of action.cards) {
						this.takeCard(this.turn, card)
					}
					const knight = this.sellPile.pop() as GameCard
					if (!knight) throw new Error('no card found')
					this.lastCards.push(knight)
					this.hands[this.turn].push(knight)
				}
				break

			case ActionType.Attack:
				{
					const knight = this.takeCard(this.turn, 'K')
					this.lastCards.push(knight)
					this.sellPile.push(knight)
					if (action.cards.length === 4) {
						const knight = this.takeCard(this.turn, 'K')
						this.lastCards.push(knight)
						this.sellPile.push(knight)
					}
					for (const card of action.cards) {
						const takenCard = this.takeCard(
							this.players.findIndex(p => p.symbol === action.player),
							card,
						)
						this.lastCards.push(takenCard)
						this.hands[this.turn].push(takenCard)
					}
				}
				break
		}
	}

	takeCard(idx: number, card: number | 'K') {
		const cardIdx = this.hands[idx].findIndex(
			handCard => handCard.symbol() === card,
		)

		if (cardIdx == -1) {
			throw new Error('no card found')
		}

		return this.hands[idx].splice(cardIdx, 1)[0]
	}

	usedCards() {
		return [
			...this.hands.reduce((prev, cur) => prev.concat(cur)),
			...this.sellPile,
		]
	}
	deckSize() {
		return (
			54 -
			this.hands.map(hand => hand.length).reduce((prev, cur) => prev + cur) -
			this.sellPile.length
		)
	}

	pickCard(card: number | 'K') {
		let usedCards = this.usedCards()
		let unused = []
		if (card === 'K') {
			unused = [new GameCard(Suit.Clubs, 14), new GameCard(Suit.Diamonds, 14)]
			for (const id of [1, 11, 12, 13]) {
				for (let suit = 0; suit < 4; suit++) {
					unused.push(new GameCard(suit, id))
				}
			}
		} else {
			for (let suit = 0; suit < 4; suit++) {
				unused.push(new GameCard(suit, card))
			}
		}
		unused = unused.filter(card => !usedCards.some(used => used.equals(card)))
		if (!unused.length) {
			throw new Error('no card found')
		}
		return unused[
			Math.abs(
				seedrandom(`${card} ${unused.length} ${usedCards.length}`).int32(),
			) % unused.length
		]
	}
}
