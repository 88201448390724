import { FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'
import { LogContext } from '../state/log'
import Row from './row'

const Player: FunctionalComponent<{
	idx: number
}> = ({ idx }) => {
	const log = useContext(LogContext)
	const position = [
		'bottom-right',
		'bottom-left',
		'left',
		'top-left',
		'top-right',
	][idx]

	return (
		<div className={`player-area ${position}`}>
			<Row>{log.state.stacks(idx)}</Row>
			<Row>{log.state.knightStacks(idx)}</Row>
			<div
				className={`player-info ${
					(log.turn - 1) % 5 === idx ? 'player-active' : ''
				}`}
			>
				<div className="player-name">
					<strong>{log.players[idx].name}</strong> ({log.players[idx].symbol})
				</div>
				<div className="counters">
					{log.state.hands[idx].length}{' '}
					{log.state.hands[idx].length === 1 ? 'card' : 'cards'} (+
					{log.state.score(idx)})
				</div>
			</div>
		</div>
	)
}

export default Player
