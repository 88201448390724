import 'preact/debug'
import { render } from 'preact'
import View from './components/view'

render(
	<>
		<View />
	</>,
	document.getElementById('app') as HTMLElement,
)
