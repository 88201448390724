import { FunctionalComponent } from 'preact'
import { LogContext } from '../state/log'
import { Suit } from '../state/state'

const Card: FunctionalComponent<{
	suit: Suit
	id: number
}> = ({ suit, id, children }) => (
	<LogContext.Consumer>
		{log => (
			<div
				className={`card-outer ${
					log.state.lastCards.some(c => c.suit === suit && c.id === id)
						? 'card-used'
						: ''
				}`}
			>
				<div
					className={`card-inner card-${Suit[suit].toLowerCase()}-${id}`}
				></div>
				{children}
			</div>
		)}
	</LogContext.Consumer>
)

export default Card
