import EventEmitter from 'events'
import { createContext } from 'preact'
import Result from '../util/result'
import GameState from './state'

export enum ActionType {
	Draw,
	Sell,
	Attack,
}

export type Action =
	| {
			type: ActionType.Draw | ActionType.Sell
			cards: (number | 'K')[]
	  }
	| {
			type: ActionType.Attack
			player: string
			cards: number[]
	  }

export class GameMove {
	string = ''

	constructor(public readonly actions: Action[], public readonly log: GameLog) {
		const logClone = log.clone()

		for (const action of this.actions) {
			let cards = action.cards.map(card => {
				if (card === 10) return 0
				return card
			})
			switch (action.type) {
				case ActionType.Draw:
					this.string += `+${cards.join('')}`
					for (const card of cards) {
						if (card === 'K') {
							if (logClone.state.count(logClone.state.turn, 'K') === 3) {
								this.string += '?'
							}
						}
						logClone.state.act({ type: ActionType.Draw, cards: [card] })
						if (logClone.state.count(logClone.state.turn, card) === 4) {
							this.string += '!'
						}
					}
					break
				case ActionType.Sell:
					this.string += `-${cards.join('')}`
					for (const card of new Set(cards)) {
						if (logClone.state.count(logClone.state.turn, card) === 4) {
							this.string += '#'
						}
					}
					logClone.state.act(action)
					break
				case ActionType.Attack:
					this.string += `=${action.player}:${cards.join('')}`
					const target = logClone.players.findIndex(
						p => p.symbol === action.player,
					)
					for (const card of new Set(cards)) {
						if (logClone.state.count(target, card) === 4) {
							this.string += '#'
						}
					}
					logClone.state.act(action)
					for (const card of new Set(cards)) {
						if (logClone.state.count(logClone.state.turn, card) === 4) {
							this.string += '!'
						}
					}
			}
		}
	}

	static parse(move: string, log: GameLog): Result<GameMove, string> {
		const actions = []

		let curAction: Partial<Action> = {}
		for (let char of move) {
			switch (char) {
				case '+':
					if (curAction.type != null && curAction.cards) {
						actions.push(curAction as Action)
					}
					curAction = { type: ActionType.Draw, cards: [] }
					break
				case '-':
					if (curAction.type != null && curAction.cards) {
						actions.push(curAction as Action)
					}
					curAction = { type: ActionType.Sell, cards: [] }
					break
				case '=':
					if (curAction.type != null && curAction.cards) {
						actions.push(curAction as Action)
					}
					curAction = { type: ActionType.Attack, player: '' }
					break
				case ':':
					if (curAction.type !== ActionType.Attack) {
						return Result.err("Unexpected ':'")
					} else if (
						!log.players.some(p => p.symbol === (curAction as any).player)
					) {
						return Result.err(
							`Invalid player symbol '${(curAction as any).player ?? ''}'`,
						)
					}
					curAction.cards = []
					break
				case '?':
				case '!':
				case '#':
					break
				case '0':
					char = '10'
				case '2':
				case '3':
				case '4':
				case '5':
				case '6':
				case '7':
				case '8':
				case '9':
				case 'K':
					if (curAction.cards) {
						if (char === 'K' && curAction.type === ActionType.Attack) {
							return Result.err("Unexpected 'K' in an attack")
						}
						curAction.cards.push((parseInt(char) || 'K') as number)
					} else {
						return Result.err(`Expected player symbol, found '${char}'`)
					}
					break
				default:
					if (curAction.type === ActionType.Attack && !curAction.cards) {
						curAction.player += char
					} else {
						return Result.err(`Unexpected '${char}'`)
					}
			}
		}
		if (curAction.type != null && curAction.cards) {
			actions.push(curAction as Action)
		}

		return Result.ok(new GameMove(actions, log))
	}

	toString() {
		return this.string
	}
}

export type Player = {
	name: string
	symbol: string
}

export default class GameLog extends EventEmitter {
	private _turn = 0
	public get turn() {
		return this._turn
	}
	semiturn = false
	state: GameState
	constructor(public players: Player[], public moves: GameMove[] = []) {
		super()
		this.state = new GameState(players)
	}

	clone() {
		const log = new GameLog(this.players, this.moves)
		log.setTurn(this.turn)
		if (this.semiturn) {
			log.reverseSemiturn()
		}
		return log
	}
	setTurn(turn: number) {
		if (turn < this.turn) {
			this._turn = 0
			this.semiturn = false
			this.state = new GameState(this.players)
			this.advanceTurns(turn)
		} else {
			this.advanceTurns(turn - this.turn)
			this.semiturn = false
		}
	}
	advanceTurns(num: number) {
		if (this.semiturn) {
			this.advanceSemiturn()
		}
		if (this.turn + num > this.moves.length) {
			num = this.moves.length - this.turn
		}
		for (let i = this.turn; i < this.turn + num; i++) {
			this.state.move(this.moves[i])
		}
		this._turn += num
		this.emit('update')
	}
	reverseTurns(num: number) {
		this.setTurn(Math.max(0, this.turn - num))
	}

	advanceSemiturn() {
		if (this.turn === this.moves.length && !this.semiturn) return
		if (this.semiturn) {
			let start = false
			for (const action of this.moves[this.turn - 1].actions) {
				if (action.type !== ActionType.Draw || start) {
					start = true
					this.state.act(action)
				}
			}
			this.semiturn = false
			this.state.advanceTurn()
		} else {
			this.state.lastCards = []
			for (const action of this.moves[this.turn].actions) {
				if (action.type === ActionType.Draw) {
					this.state.act(action)
				} else {
					this.semiturn = true
					break
				}
			}
			if (!this.semiturn) {
				this.state.advanceTurn()
			}
			this._turn += 1
		}
		this.emit('update')
	}
	onSemiturn() {
		if (this.turn === 0) return false
		return this.moves[this.turn - 1].actions.some(
			a => a.type !== ActionType.Draw,
		)
	}
	reverseSemiturn() {
		if (!this.semiturn && this.onSemiturn()) {
			this.reverseTurns(1)
			this.advanceSemiturn()
		} else {
			this.reverseTurns(1)
		}
	}

	loadGame(players: Player[], moves: string[]): Result<this, string> {
		let log = new GameLog(players)
		for (const move of moves) {
			try {
				const moveObj = GameMove.parse(move, log)
				if (moveObj.isErr()) {
					return Result.err(moveObj.unwrapErr())
				} else {
					log.moves.push(moveObj.unwrap())
					log.advanceTurns(1)
				}
			} catch (e) {
				return Result.err(
					`Unable to simulate turn ${
						log.turn + 1
					} (most likely, a move was inputted incorrectly)`,
				)
			}
		}

		this.state = new GameState(players)
		this.players = players
		this.semiturn = false
		this._turn = 0
		this.moves = log.moves
		this.emit('update')
		return Result.ok(this)
	}
}

export const LogContext = createContext(new GameLog([]))
